.width-100 {
    width: 100%;
}

.height-100 {
    height: 100% !important;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.center {
    justify-content: center;
}

.v-center {
    align-items: center;
}

.h-center {
    align-items: center;
}

.end {
    justify-content: end;
}


.main-container {
    max-width: 1200px;
    height: 100%;
    min-height: 100vh;
    /* height: 100vh; */
}

.ellipsis-div {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.json-tree {
    max-width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modal-json-tree {
    /* width: 80vh; */
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.logout-button {
    width: 150px;
    margin-left: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.insights-chart-container {
    height: 30vh;
    /* padding: 5px; */
    /* height: 100%; */
}

/* .process-table-fixed-height tbody {
    height: 65vh;
} */

/* row data */
/* .ant-table-tbody>tr>td {
    height: 1px;
    padding: 1px;
} */

.ant-table-body {
    min-height: 60vh;
}

.ant-table-body-no-data .ant-table-tbody {
    height: 60vh;
}


@media only screen and (min-width: 1200px) {
    .main-container {
        border: 1px solid RGB(211, 211, 211);
    }
}

@media only screen and (min-width: 1536px) {
    /* .process-table-fixed-height tbody {
        height: 75vh;
    } */

    .ant-table-body {
        height: 75vh;
    }

    .ant-table-body-no-data .ant-table-tbody {
        height: 75vh;
    }
}